.services_content{
    padding-top: 10%;
    padding-bottom: 10%;
}
.slider_div img{
    width: 80% !important;
    height: 60% !important;
    border-radius: 20px !important;
}
.slider_div{
    text-align: center;
    padding-top: 3%;
}
.services_pictures {
    text-align: center !important;
}
.services_pictures img{
    padding-top: 3%;
}
.services_main_content h2{
    padding-top: 3%;
    text-align: center;
    color: #085CBC;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 300%;
}
.services_main_content p{
    padding-top: 2%;
    margin-left: 15%;
    margin-right: 15%;
    color: #7E7E7E;
    text-align: center;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 150%;
}
.blue_text_services p{
    padding-top: 2%;
    margin-left: 15%;
    margin-right: 15%;
    color: #085CBC;
    text-align: center;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 150%;
}
.services_main_content {
    text-align: center;
}
.services_main_content img{
    padding-top: 3%;
}
.sc-bcXHqe.egvqvJ {
    display: none;
}

.sc-dkrFOg.ckKsfl {
    display: none;
}
.slider_image{
    border-radius: 20%;
}

.keen-slider__track{
    display : flex;
    width: 100%;
}
.slide img{
    border-radius: 10px;
}
@media screen and (max-width: 600px){
    .slide{
        text-align: center;
    }
    .slide img{
        width: 80%;
    }
    .services_content{
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .slider_div img{
        width: 80% !important;
        height: 30% !important;
        border-radius: 20px !important;
    }
    .slider_div{
        text-align: center;
        padding-top: 3%;

        padding-bottom: -70% !important;
        margin-bottom: -70% !important;
    }
    .services_pictures {
        text-align: center !important;
    }
    .services_pictures img{
        padding-top: 3%;
        width: 80%;
    }
    .services_main_content h2{
        padding-top: 3%;
        text-align: center;
        color: #085CBC;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 150%;
    }
    .services_main_content p{
        padding-top: 2%;
        margin-left: 15%;
        margin-right: 15%;
        color: #7E7E7E;
        text-align: center;
        font-family: 'Lato';
        font-weight: 300;
        font-size: 100%;
    }
    .blue_text_services p{
        padding-top: 2%;
        margin-left: 15%;
        margin-right: 15%;
        color: #085CBC;
        text-align: center;
        font-family: 'Lato';
        font-weight: 300;
        font-size: 100%;
    }
    .services_main_content {
        text-align: center;
    }
    .services_main_content img{
        padding-top: 3%;
        border-radius: 10px;
    }
}
