.about_content{
    padding-top: 10%;
    padding-bottom: 10%;
}
.about_pictures {
    text-align: center;
}
.about_pictures img{
    padding-top: 5%;
}
.about_main_content h2{
    padding-top: 3%;
    text-align: center;
    color: #085CBC;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 300%;
}
.about_main_content p{
    padding-top: 2%;
    margin-left: 15%;
    margin-right: 15%;
    color: #085CBC;
    text-align: center;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 150%;
}
.about_main_content {
    text-align: center;
}
.about_main_content img{
    padding-top: 5%;
}

@media screen and (max-width: 600px){
    .about_content{
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .about_pictures {
        text-align: center;
    }
    .about_pictures img{
        padding-top: 5%;
        width: 80%;
    }
    .about_main_content h2{
        padding-top: 3%;
        text-align: center;
        color: #085CBC;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 130%;
    }
    .about_main_content p{
        padding-top: 2%;
        margin-left: 15%;
        margin-right: 15%;
        color: #085CBC;
        text-align: center;
        font-family: 'Lato';
        font-weight: 300;
        font-size: 100%;
    }
    .about_main_content {
        text-align: center;
    }
    .about_main_content img{
        padding-top: 5%;
        width: 80%;
    }
}