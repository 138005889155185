.navmain_wrapper{
    padding-right: 15%;
    padding-left: 15%;
    padding-top: 2%;
}
div#offcanvasNavbar-expand-md{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.unselected_nav_item{
    text-align: center;
    font-family: 'Outfit';
    font-size: 150%;
    color: #7E7E7E !important;
    padding-right: 2% !important;
}
.selected_nav_item{
    text-align: center;
    font-family: 'Outfit';
    font-size: 150%;    
    color: #0093A1 !important;
    padding-right: 2% !important;
}
.contact_nav_item{
    text-align: center;
    font-family: 'Outfit';
    font-size: 150%;
    padding: 1%;
    color: #fff !important;
    background-color: #0093A1;
}

.navbar-brand img{
    height: 25% !important;
    width: 25% !important;
    display: inline-block;
}
.navbar-brand .brand_name{
    font-size: 180%;
    color: #0093A1;
}
.navbar-brand .brand_sub_name{
    padding-top: 0% !important;
    font-size: 80%;
    color: #7E7E7E;
}
.navbar-brand .brand-side-nav{
    display: inline-block;
}
span.navbar-toggler-icon{
    filter: contrast(0);
    color: white !important;
}
@media screen and (min-width:600px) {
    svg{
        display: none;
    }
}
@media screen and (max-width: 600px){
    ModalImage{
        display: none;
    }
    .navbar-brand{
        padding-right: -1%;
        width: 70%;
    }
    .navbar-brand img{
        height: 25% !important;
        width: 25% !important;
        display: inline-block;
    }
    .navbar-brand .brand_name{
        font-size: 90% !important;
        color: #0093A1;
    }
    .navbar-brand .brand_sub_name{
        font-size: 50% !important;
        color: #7E7E7E;
    }
    .navmain_wrapper{
        padding-right: 3%;
        padding-left: 3%;
        padding-top: 2%;
    }
    .brand-side-nav{
        padding-top: 3%;
    }
    .contact_nav_item{
        display: none !important;
    }
}